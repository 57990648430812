<template>
  <section id="hero" class="position-relative">
    <!-- ������ ������ ���� -->
    <v-container class="fill-height px-4 py-12 position-absolute top-0 left-0">
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        max-width="700"
        width="100%"
      >
        <BaseSubheading
          size="text-h4"
          title="Welcome to the 26th International Society for Music Information Retrieval Conference"
          weight="regular"
          class="text-white"
        />

        <BaseHeading
          size="text-h1"
          title="ISMIR 2025"
          weight="medium"
          class="text-white"
        />

        <BaseBody class="text-white" fontSize="25px">
          Sep 21-25, Daejeon, Korea
        </BaseBody>

        <!--
        <div
          :class="smAndDown ? 'flex-column align-start' : 'align-center'"
          class="d-flex flex-wrap"
        >
          <BaseBtn @click="goToImportantDates">
            Important Dates
          </BaseBtn>

          <span class="font-weight-bold ml-6 mr-4 my-4 text-white">or</span>

          <BaseBtn
            :ripple="false"
            color="white"
            class="pa-1"
            height="auto"
            text
            href="https://mac.kaist.ac.kr"
            target="_blank"
          >
            Registration
          </BaseBtn>


        </div>
        -->
      </v-responsive>
    </v-container>

    <!-- ���� ���� ������ �����̵� ������ -->
    <v-carousel
      :height="carouselHeight"
      :show-arrows="false"
      :interval="5000"
      :cycle="true"
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="i"
        :src="image.src"
        cover
      >
        <div class="gradient-overlay"></div>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script setup>
import { ref } from "vue";
//import { useRouter } from 'vue-router'
import k1 from "@/assets/CoverImages/k1.jpg";
import k2 from "@/assets/CoverImages/k2.jpg";
import k3 from "@/assets/CoverImages/k3.jpg";
import d1 from "@/assets/CoverImages/d1.jpg";
import d2 from "@/assets/CoverImages/d2.jpg";
import d3 from "@/assets/CoverImages/d3.jpg";
//import s1 from '@/assets/CoverImages/s1.jpg'
//import s2 from '@/assets/CoverImages/s2.jpg'
//import s3 from '@/assets/CoverImages/s3.jpg'
//import s4 from '@/assets/CoverImages/s4.jpg'
import BaseHeading from "@/components/base/BaseHeading.vue";
import BaseSubheading from "@/components/base/BaseSubheading.vue";
import BaseBody from "@/components/base/BaseBody.vue";
//import BaseBtn from '@/components/base/BaseBtn.vue'

const images = [
  { src: k1 },
  { src: d1 },
  //{ src: s1 },
  { src: k2 },
  { src: d2 },
  //{ src: s2 },
  { src: k3 },
  { src: d3 },
  //{ src: s3 },
  //{ src: s4 },
];

// ���̸� �������� �����ϱ� ���� ����
const carouselHeight = ref("65vh"); // �⺻ ���̸� '70vh'�� ����
/*
// vue-router ��� ����
const router = useRouter()

// Important Dates �������� �̵��ϴ� �Լ�
const goToImportantDates = () => {
  router.push({ name: 'ImportantDatesPage' }) // ������ �̸��� ���� �̵�
}
*/
</script>

<style scoped>
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
  z-index: 1;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(11, 28, 61, 0.5),
    rgba(0, 0, 0, 0.4)
  );
  z-index: 1; /* �׶��̼��� �̹��� ���� ǥ�õǵ��� ���� */
}
</style>
